import React from 'react';
import { Container, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

// Styled components
const Root = styled('div')(({ theme }) => ({
  backgroundColor: '#f5f5f5',
  padding: theme.spacing(8, 0),
}));

const IframeContainer = styled('div')({
  position: 'relative',
  width: '100%',
  overflow: 'hidden',
  paddingTop: '56.25%', // 16:9 Aspect Ratio
});

const ResponsiveIframe = styled('iframe')({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  border: 'none',
});

const Testimonials = () => {
  return (
    <Root>
      <Container maxWidth="lg">
        <Typography variant="h4" align="center" gutterBottom>
          Testimonials
        </Typography>

        {/* Embedding SociableKIT Google Reviews via Responsive Iframe */}
        <IframeContainer>
          <ResponsiveIframe
            src="https://widgets.sociablekit.com/google-reviews/iframe/25477357"
            title="Google Reviews"
            allowFullScreen
          ></ResponsiveIframe>
        </IframeContainer>
      </Container>
    </Root>
  );
};

export default Testimonials;
