import React, { useState } from 'react';
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Divider,
  useMediaQuery,
  IconButton,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';  
// Correctly import the image
import logo from '../images/sflogo.png';

const Navbar = ({ onHeroClick, onProjectsClick, onExperienceClick, onSkillsClick, onServicesClick, onTestimonialClick, onFooterClick }) => {
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const [openDrawer, setOpenDrawer] = useState(false);

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setOpenDrawer(open);
  };

  const list = () => (
    <div
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <List>
       
        <ListItem button onClick={onHeroClick}>
          <ListItemText primary="Home" />
        </ListItem>  
        <ListItem button onClick={onServicesClick}>
          <ListItemText primary="Services" />
        </ListItem>
        <ListItem button onClick={onTestimonialClick}>
          <ListItemText primary="Testimonials" />
        </ListItem>   
        <ListItem button onClick={onFooterClick}>
          <ListItemText primary="RequestQuote" />
        </ListItem>     
      </List>
      <Divider />
    </div>
  );

  return (
    <div>
      <AppBar position="static" sx={{ backgroundColor: '#1b77bb' }}>
        <Toolbar>
          {/* Logo and App Name */}
          <Typography variant="h6" component="div" sx={{ mr: 'auto', display: 'flex', alignItems: 'center' }}>
            <a href="/" style={{ textDecoration: 'none', color: 'inherit', display: 'flex', alignItems: 'center' }} onClick={onHeroClick}>
              <img 
                src={logo}  // Use the imported logo here
                alt="Logo"
                style={{ width: '40px', height: '40px', marginRight: '10px' }} // Adjust the size and margin as needed
              />
              <span style={{ whiteSpace: 'nowrap' }}>SF Ali Travels</span> {/* Prevents name wrapping */}
            </a>
          </Typography>
          
          {isSmallScreen ? (
            <IconButton edge="end" color="inherit" onClick={toggleDrawer(true)}>
              <MenuIcon /> {/* Menu Icon */}
            </IconButton>
          ) : (
            <Toolbar sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <Button color="inherit" sx={buttonStyle} onClick={onHeroClick}>Home</Button>
              <Button color="inherit" sx={buttonStyle} onClick={onServicesClick}>Services</Button>
              <Button color="inherit" sx={buttonStyle} onClick={onTestimonialClick}>Testimonials</Button>
              <Button color="inherit" sx={buttonStyle} onClick={onFooterClick}>Contact us</Button>
            </Toolbar>
          )}
        </Toolbar>
      </AppBar>
      
      {/* Drawer for mobile screen */}
      <Drawer
        anchor="right"
        open={isSmallScreen && openDrawer}
        onClose={toggleDrawer(false)}
      >
        {list()}
      </Drawer>
    </div>
  );
};

const buttonStyle = {
  '&:hover': {
    textDecoration: 'underline',
    textDecorationColor: '#000000',
    textDecorationThickness: '0.3em',
  },
};

export default Navbar;
